import { NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, signal, ViewChild } from '@angular/core';
import { AnimateModule } from '../../../../components/animate/animate.module';

@Component({
  selector: 'app-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, AnimateModule],
})
export class VideoSectionComponent {
  @ViewChild('video', { static: true, read: ElementRef })
  public video?: ElementRef;

  public readonly playing = signal<boolean>(false);

  public playVideo(): void {
    if (this.video) {
      this.video.nativeElement.play().then(() => {
        this.playing.set(true);
      });
    }
  }
}
