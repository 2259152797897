<section class="app-wha-tod">
  <h2
    [appAnimate]="'landing'"
    [speed]="'fast'"
    [aos]="0.2"
    [once]="true"
    [delay]="'100'"
    class="app-wha-tod--h2 app-title text-center"
  >
    Co zrobić by wygrać?
  </h2>

  <div class="container app-wha-tod__con">
    <ul class="list-unstyled p-0 app-wha-tod__con__ul">
      <li
        class="app-wha-tod__con__ul__li"
        [appAnimate]="'bounceInRight'"
        [aos]="0.2"
        [once]="true"
        [speed]="'slow'"
        [delay]="'100'"
      >
        <figure class="m-0 app-wha-tod__con__ul__li__fi">
          <img
            ngSrc="assets/images/what-todo/first.webp"
            alt="first"
            width="290"
            height="584"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-none d-md-block"
          />
          <img
            ngSrc="assets/images/what-todo/first-mob.webp"
            alt="first"
            width="153"
            height="173"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-block d-md-none"
          />
          <figcaption class="app-wha-tod__con__ul__li__fi__fig">
            <p class="app-wha-tod__con__ul__li__fi__fig--p">
              Kup dowolny produkt <span class="d-inline d-md-none">Foxy</span>
              <img
                ngSrc="assets/images/logo_foxy.webp"
                alt="logo foxy"
                width="400"
                height="268"
                priority="false"
                class="app-wha-tod__con__ul__li__fi__fig--p--img d-none d-md-block"
              />
              <br />
              i zachowaj dowód zakupu
            </p>
          </figcaption>
        </figure>
      </li>
      <li
        class="app-wha-tod__con__ul__li"
        [appAnimate]="'bounceInRight'"
        [aos]="0.2"
        [once]="true"
        [speed]="'slow'"
        [delay]="'400'"
      >
        <figure class="m-0 app-wha-tod__con__ul__li__fi">
          <img
            ngSrc="assets/images/what-todo/second.webp"
            alt="second"
            width="446"
            height="584"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-none d-md-block"
          />
          <img
            ngSrc="assets/images/what-todo/second-mob.webp"
            alt="second"
            width="188"
            height="326"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-block d-md-none"
          />
          <figcaption class="app-wha-tod__con__ul__li__fi__fig">
            <p class="app-wha-tod__con__ul__li__fi__fig--p">
              Wypełnij poniższy formularz <br />
              i sprawdź czy pod e-zdrapką <br />
              kryje się nagroda
            </p>
          </figcaption>
        </figure>
      </li>
      <li
        class="app-wha-tod__con__ul__li"
        [appAnimate]="'bounceInRight'"
        [aos]="0.2"
        [once]="true"
        [speed]="'slow'"
        [delay]="'700'"
      >
        <figure class="m-0 app-wha-tod__con__ul__li__fi">
          <img
            ngSrc="assets/images/what-todo/third.webp"
            alt="third"
            width="464"
            height="584"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-none d-md-block"
          />
          <img
            ngSrc="assets/images/what-todo/third-mob.webp"
            alt="third"
            width="345"
            height="292"
            priority="false"
            class="app-wha-tod__con__ul__li__fi--img d-block d-md-none"
          />
          <figcaption class="app-wha-tod__con__ul__li__fi__fig">
            <p class="app-wha-tod__con__ul__li__fi__fig--p">
              Codziennie do wygrania <br />
              <strong>5 000zł i e-vouchery</strong> <br />
              na rodzinne przyjemności.
            </p>
          </figcaption>
        </figure>
      </li>
    </ul>
  </div>

  <p
    class="app-wha-tod--p text-center"
    [appAnimate]="'landing'"
    [speed]="'fast'"
    [aos]="0.2"
    [once]="true"
    [delay]="'100'"
  >
    <span>
      Akcja trwa od <strong>01.05.2024&nbsp;r.</strong> do
      <strong>30.06.2024&nbsp;r.</strong>
    </span>
  </p>
</section>
