<app-header (navigationClick)="onNavigationClick($event)" />

<main class="app-con">
  <app-top-section #topSection />
  <app-rewards-section #rewardsSection />
  <app-video-section #videoSection />
  <app-what-todo-section #whatTodoSection />
  <app-products-section #productsSection />
  <app-winners-section #winnersSection [winners]="(winners$ | async) || []" />
  <app-follow-section #followSection />
  <app-faq-section #faqSection />
</main>

<app-scroll-to-top />
<app-footer />
