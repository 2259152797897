import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideNgxMask } from 'ngx-mask';
import { provideHttpClient } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  RECAPTCHA_LOADER_OPTIONS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { environment } from '../environments/environment';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';

registerLocaleData(localePl);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideToastr({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    provideNgxMask(),
    provideHttpClient(),
    importProvidersFrom(ModalModule.forRoot()),
    importProvidersFrom(RecaptchaV3Module),
    importProvidersFrom(Angulartics2Module.forRoot()),
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', locale);
          return { url };
        },
      }),
      deps: [LOCALE_ID],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
  ],
};
