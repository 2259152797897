import { NgOptimizedImage } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { AnimateModule } from '../../../../components/animate/animate.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../../directives/swiper.directive';

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgOptimizedImage, AnimateModule, SwiperDirective],
})
export class ProductsSectionComponent {
  @ViewChild('swiper', { static: false })
  public swiperElementRef: ElementRef | null = null;

  private readonly _deviceDetectorService = inject(DeviceDetectorService);

  public readonly isMobile = this._deviceDetectorService.isMobile();

  public readonly swiperConfig: SwiperOptions = {
    spaceBetween: 18,
    loop: true,
    width: 232,
    slidesPerView: 'auto',
  };

  public readonly products: {
    category: string;
    moreTxt: string;
    link: string;
    list: {
      name: string;
      image: string;
      url: string;
    }[];
  }[] = [
    {
      category: 'Papier toaletowy',
      moreTxt: 'Więcej o Papierach Foxy',
      link: 'https://www.foxy.eu/pl/nasze-produkty/papier-toaletowy/',
      list: [
        {
          name: ' Foxy Love the Bee',
          image: 'papier-toaletowy-foxy-love-the-bee.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-love-the-bee-papier-toaletowy/?_gl=1*1mqfiz7*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Jedwab',
          image: 'papier-toaletowy-foxy-jedwab.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-jedwab-papier-toaletowy/?_gl=1*1mqfiz7*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Artistico',
          image: 'papier-toaletowy-foxy-artistico.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-artistico-papier-toaletowy/?_gl=1*1mqfiz7*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Camilla',
          image: 'papier-toaletowy-foxy-camilla.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-camilla-papier-toaletowy/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Cotton',
          image: 'papier-toaletowy-foxy-cotton.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cotton-papier-toaletowy/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Mega',
          image: 'papier-toaletowy-foxy-mega.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-mega-papier-toaletowy/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Quatrefeuilles',
          image: 'papier-toaletowy-foxy-quatrefeuilles.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-quatrefeuilles-papier-toaletowy/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
      ],
    },
    {
      category: 'Ręczniki kuchenne',
      moreTxt: 'Więcej o Ręcznikach Foxy',
      link: 'https://www.foxy.eu/pl/nasze-produkty/recznik/',
      list: [
        {
          name: 'Foxy Mega',
          image: 'reczniki-kuchenne-foxy-mega.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-mega-recznik-kuchenny/?_gl=1*1dgteko*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Love the Bee',
          image: 'reczniki-kuchenne-foxy-love-the-bee.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-love-the-bee/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Mega Jumbo',
          image: 'reczniki-kuchenne-foxy-mega-jumbo.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-mega-jumbo-recznik-kuchenny/?_gl=1*1ow3x20*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Tornado',
          image: 'reczniki-kuchenne-foxy-tornado.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-tornado-reczniki-kuchenne/?_gl=1*1dgteko*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Asso',
          image: 'reczniki-kuchenne-foxy-asso.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-asso-reczniki-kuchenne/?_gl=1*1dgteko*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Cartapaglia',
          image: 'reczniki-kuchenne-foxy-cartapaglia.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cartapaglia-reczniki-kuchenne/?_gl=1*1dgteko*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Asso Ultra',
          image: 'reczniki-kuchenne-foxy-asso-ultra.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-asso-ultra-reczniki-kuchenne/?_gl=1*1dgteko*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
      ],
    },
    {
      category: 'Chusteczki kosmetyczne',
      moreTxt: 'Więcej o Chusteczkach Foxy',
      link: 'https://www.foxy.eu/pl/nasze-produkty/chusteczki-kosmetyczne/',
      list: [
        {
          name: 'Foxy Mega',
          image: 'chusteczki-kosmetyczne-foxy-mega.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-mega-chusteczki-kosmetyczne/?_gl=1*19i53tq*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Cotton',
          image: 'chusteczki-kosmetyczne-foxy-cotton.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cotton-chusteczki-kosmetyczne/?_gl=1*19i53tq*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Cream',
          image: 'chusteczki-kosmetyczne-foxy-cream.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cream-chusteczki-kosmetyczne/?_gl=1*19i53tq*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
      ],
    },
    {
      category: 'Chusteczki higieniczne',
      moreTxt: 'Więcej o Chusteczkach Foxy',
      link: 'https://www.foxy.eu/pl/nasze-produkty/chusteczki/',
      list: [
        {
          name: 'Foxy Cream',
          image: 'chusteczki-higieniczne-foxy-cream.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cream-chusteczki-higieniczne/?_gl=1*1frnyrn*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Hipoalergiczne',
          image: 'chusteczki-higieniczne-foxy-hipoalergiczne.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-hipoalergiczne-chusteczki-higieniczne/?_gl=1*1frnyrn*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Cotton',
          image: 'chusteczki-higieniczne-foxy-cotton.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-cotton-chusteczki-higieniczne/?_gl=1*19i53tq*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
        {
          name: 'Foxy Mega',
          image: 'chusteczki-higieniczne-foxy-mega.webp',
          url: 'https://www.foxy.eu/pl/produkty/foxy-mega-chusteczki-higieniczne/?_gl=1*19i53tq*_up*MQ..*_ga*NTEwMzkzNjkuMTcxMjc0NDI4MQ..*_ga_9V7VDVTMJK*MTcxMjc0NDI4MC4xLjAuMTcxMjc0NDI4MC4wLjAuMA..',
        },
      ],
    },
  ];
}
