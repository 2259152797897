import { AfterViewInit, Component, inject } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { GoogleTagManagerService } from '@app/services/google-tag-manager.service';

@Component({
  standalone: true,
  imports: [NgxSpinnerModule, RouterModule],
  selector: 'app-root',
  template: `
    <router-outlet />
    <ngx-spinner
      [bdColor]="'rgb(24, 46, 149, 0.75)'"
      [color]="'#fff'"
      [fullScreen]="true"
      [size]="'large'"
      [type]="'ball-scale-multiple'"
    >
      <p style="color: #fff">Ładowanie...</p>
    </ngx-spinner>
  `,
})
export class AppComponent implements AfterViewInit {
  private readonly _googleTagManagerService = inject(GoogleTagManagerService);
  private readonly _angulartics2GoogleTagManager = inject(
    Angulartics2GoogleTagManager,
  );

  public ngAfterViewInit(): void {
    this._googleTagManagerService
      .addScriptToDom()
      .then(() => {
        this._angulartics2GoogleTagManager.startTracking();
        this._googleTagManagerService.gtag('event', 'conversion', {
          allow_custom_scripts: true,
          u1: window.location.href,
          u50: '',
          send_to: 'DC-13117613/licz/lote-wys+standard',
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
