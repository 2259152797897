export enum SectionType {
  TOP = 'top',
  REWARDS = 'rewards',
  WHAT_TODO = 'what-todo',
  RULES = 'rules',
  LOTTERY_ENTRY = 'lottery-entry',
  PRODUCTS = 'products',
  WINNERS_LIST = 'winners-list',
  FAQ = 'faq',
}
