<section class="app-vid">
  <h2
    [appAnimate]="'landing'"
    [speed]="'fast'"
    [aos]="0.2"
    [once]="true"
    [delay]="'100'"
    class="app-vid--h2 app-title text-center"
  >
    Obejrzyj reklamę TV
  </h2>

  <div
    class="app-vid__con"
    [appAnimate]="'landing'"
    [speed]="'fast'"
    [aos]="0.2"
    [once]="true"
    [delay]="'300'"
  >
    @if (!playing()) {
      <img
        ngSrc="assets/images/video/video-poster.svg"
        alt="poster"
        priority="false"
        [fill]="true"
        class="app-vid__con--pos"
        (click)="playVideo()"
      />
    }
    <video
      [autoplay]="false"
      [loop]="false"
      [muted]="false"
      [controls]="playing()"
      [preload]="'auto'"
      #video
      class="app-vid__con--vid"
    >
      <source
        src="assets/videos/FOXY_online_5s_PRORES_422HQ_09042024.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
</section>
