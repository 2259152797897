import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, tap } from 'rxjs';
import { RewardsSectionComponent } from './components/rewards-section/rewards-section.component';
import { WhatTodoSectionComponent } from './components/what-todo-section/what-todo-section.component';
import { TopSectionComponent } from './components/top-section/top-section.component';
import { VideoSectionComponent } from './components/video-section/video-section.component';
import { FollowSectionComponent } from './components/follow-section/follow-section.component';
import { FaqSectionComponent } from './components/faq-section/faq-section.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { ProductsSectionComponent } from './components/products-section/products-section.component';
import { WinnersSectionComponent } from './components/winners-section/winners-section.component';
import { ApiService } from '@app/services/api.service';
import { SectionType } from '@app/models/section-type';
import { WinnerListItem } from '@app/models/winner-list-item';
import { AsyncPipe } from '@angular/common';
import { GoogleTagManagerService } from '@app/services/google-tag-manager.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    RewardsSectionComponent,
    WhatTodoSectionComponent,
    TopSectionComponent,
    VideoSectionComponent,
    FollowSectionComponent,
    FaqSectionComponent,
    ScrollToTopComponent,
    ProductsSectionComponent,
    WinnersSectionComponent,
    AsyncPipe,
  ],
  templateUrl: './main-page.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements AfterViewInit {
  @ViewChild('topSection', { static: false, read: ElementRef })
  public topSection?: ElementRef;
  @ViewChild('rewardsSection', { static: false, read: ElementRef })
  public rewardsSection?: ElementRef;
  @ViewChild('videoSection', { static: false, read: ElementRef })
  public videoSection?: ElementRef;
  @ViewChild('whatTodoSection', { static: false, read: ElementRef })
  public whatTodoSection?: ElementRef;
  @ViewChild('formSection', { static: false, read: ElementRef })
  public formSection?: ElementRef;
  @ViewChild('productsSection', { static: false, read: ElementRef })
  public productsSection?: ElementRef;
  @ViewChild('winnersSection', { static: false, read: ElementRef })
  public winnersSection?: ElementRef;
  @ViewChild('followSection', { static: false, read: ElementRef })
  public followSection?: ElementRef;
  @ViewChild('faqSection', { static: false, read: ElementRef })
  public faqSection?: ElementRef;

  private readonly _apiService = inject(ApiService);
  public winners$: Observable<WinnerListItem[]> = this._apiService.getWinners();
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _googleTagManagerService = inject(GoogleTagManagerService);

  private readonly _scrollSubject = new Subject<void>();
  private readonly _scroll$ = this._scrollSubject.asObservable();

  public ngAfterViewInit(): void {
    this._setScrollSubscription();
  }

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    this._scrollSubject.next();
  }

  public onNavigationClick(sectionType: SectionType): void {
    switch (sectionType) {
      case SectionType.RULES:
        this._scroll(this.whatTodoSection);
        break;
      case SectionType.LOTTERY_ENTRY:
        this._scroll(this.formSection);
        break;
      case SectionType.PRODUCTS:
        this._scroll(this.productsSection);
        break;
      case SectionType.WINNERS_LIST:
        this._scroll(this.winnersSection);
        break;
      case SectionType.FAQ:
        this._scroll(this.faqSection);
        break;
    }
  }

  private _setScrollSubscription(): void {
    const wasScrolled = [false, false, false, false];
    this._scroll$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        debounceTime(500),
        tap(() => {
          const scrollDepth = this._getScrollDepth();
          if (scrollDepth > 25 && scrollDepth < 50 && !wasScrolled[0]) {
            this._googleTagManagerService.gtag('event', 'conversion', {
              allow_custom_scripts: true,
              u1: window.location.href,
              u4: '25',
              u50: '',
              send_to: 'DC-13117613/licz/lote-scr+standard',
            });
            wasScrolled[0] = true;
          } else if (scrollDepth > 50 && scrollDepth < 75 && !wasScrolled[1]) {
            this._googleTagManagerService.gtag('event', 'conversion', {
              allow_custom_scripts: true,
              u1: window.location.href,
              u4: '50',
              u50: '',
              send_to: 'DC-13117613/licz/lote-scr+standard',
            });
            wasScrolled[1] = true;
          } else if (scrollDepth > 75 && scrollDepth < 100 && !wasScrolled[2]) {
            this._googleTagManagerService.gtag('event', 'conversion', {
              allow_custom_scripts: true,
              u1: window.location.href,
              u4: '75',
              u50: '',
              send_to: 'DC-13117613/licz/lote-scr+standard',
            });
            wasScrolled[2] = true;
          } else if (scrollDepth === 100 && !wasScrolled[3]) {
            this._googleTagManagerService.gtag('event', 'conversion', {
              allow_custom_scripts: true,
              u1: window.location.href,
              u4: '100',
              u50: '',
              send_to: 'DC-13117613/licz/lote-scr+standard',
            });
            wasScrolled[3] = true;
          }
        }),
      )
      .subscribe();
  }

  private _getScrollDepth() {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;
    const clientHeight = document.documentElement.clientHeight;
    return Math.round((scrollTop / (scrollHeight - clientHeight)) * 100);
  }

  private _scroll(elementRef: ElementRef | undefined): void {
    if (!elementRef) {
      return;
    }
    elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
