<section class="app-top">
  <div class="app-top__con">
    <img
      ngSrc="assets/images/top/top-elements.webp"
      alt="Wygrywaj codziennie 5000zł plus vouchery"
      width="2956"
      height="1684"
      priority="true"
      class="app-top__con--img d-none d-md-block"
      [appAnimate]="'landing'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
    />
    <img
      ngSrc="assets/images/top/top-elements-mob.webp"
      alt="Wygrywaj codziennie 5000zł plus vouchery"
      width="780"
      height="1254"
      priority="true"
      class="app-top__con--img d-block d-md-none"
      [appAnimate]="'landing'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
    />

    <p
      class="app-top__con--p"
      [appAnimate]="'landing'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
      [delay]="'200'"
    >
      Zgłoszenie do Loterii Foxy <br class="d-none d-md-block" />
      były przyjmowane do 30.06.2024 <br class="d-none d-md-block" />
      A losowanie niewydanych nagród <br class="d-none d-md-block" />
      odbędzie się 26.08.2024.
    </p>

    <img
      [appAnimate]="'landing'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
      [delay]="'400'"
      ngSrc="assets/images/top/top-products-mob.webp"
      alt="Produkty Foxy"
      width="780"
      height="570"
      priority="true"
      class="app-top__con--pro d-block d-md-none"
    />

    <a
      class="app-top__con--a app-btn"
      [appAnimate]="'slideInUp'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
      href="https://www.foxy.eu/pl/"
      target="_blank"
    >
      WIĘCEJ O MARCE FOXY
    </a>
  </div>
</section>
