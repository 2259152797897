<section class="app-faq">
  <h2 class="app-faq--h2 app-title text-center">FAQ</h2>

  <p class="app-faq--p">Odpowiedzi na najczęściej zadawane pytania</p>

  <div class="container app-faq__con">
    <div>
      <accordion class="app-faq__con__acc" [isAnimated]="true">
        @for (q of questions; track i; let i = $index) {
          <accordion-group class="app-faq__con__acc__gr">
            <button
              class="btn btn-link"
              accordion-heading
              type="button"
              [innerHTML]="i + 1 + '. ' + q.question"
            ></button>
            <span [innerHTML]="q.answer"> </span>
          </accordion-group>
        }
      </accordion>
    </div>

    <ul class="app-faq__con__ul list-unstyled m-0 p-0">
      <li class="app-faq__con__ul__li">
        <div>
          <img
            ngSrc="assets/images/faq/email.svg"
            alt="email"
            height="26"
            width="34"
            priority="false"
          />
        </div>
        <p>
          <a href="mailto:loteriafoxy@h2h.tech"> loteriafoxy&#64;h2h.tech </a>
        </p>
      </li>
      <li class="app-faq__con__ul__li">
        <div>
          <img
            ngSrc="assets/images/faq/regulations.svg"
            alt="regulations"
            height="54"
            width="28"
            priority="false"
          />
        </div>
        <p>
          <a href="assets/files/regulamin.pdf" target="_blank"> Regulamin </a>
        </p>
      </li>
      <li class="app-faq__con__ul__li">
        <div>
          <img
            ngSrc="assets/images/faq/organizer.svg"
            alt="organizer"
            height="37"
            width="37"
            priority="false"
          />
        </div>
        <p>Organizator loterii</p>
        <p>
          <a href="https://h2h.tech/contact" target="_blank"
            >Human2Human Sp. z o.o.</a
          >
        </p>
      </li>
    </ul>
  </div>
</section>
