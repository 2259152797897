<footer class="app-foo">
  <div class="app-foo__con container">
    <div class="app-foo__con__top">
      <img
        ngSrc="assets/images/logo_foxy.webp"
        alt="Logo Foxy"
        priority="false"
        height="268"
        width="400"
        class="app-foo__con__top--log"
      />

      <a
        class="app-btn"
        href="https://www.foxy.eu/pl/nasze-produkty/"
        target="_blank"
      >
        WIĘCEJ O PRODUKTACH FOXY
      </a>

      <ul class="list-unstyled m-0 p-0">
        <li>
          <a target="_blank" href="https://www.youtube.com/user/FoxyPolska">
            <img
              ngSrc="assets/images/youtube.svg"
              alt="youtube"
              priority="false"
              height="46"
              width="46"
            />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.facebook.com/Foxy.Polska">
            <img
              ngSrc="assets/images/facebook.svg"
              alt="facebook"
              priority="false"
              height="46"
              width="46"
            />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/foxy.polska/">
            <img
              ngSrc="assets/images/instagram.svg"
              alt="instagram"
              priority="false"
              height="46"
              width="46"
            />
          </a>
        </li>
      </ul>
    </div>

    <p class="app-foo__con--p">
      Foxy 2023 jest marką Industrie Cartarie Tronchetti S.p.A –
      Cod.&nbsp;fisc.&nbsp;e&nbsp;P.&nbsp;IVA&nbsp;e&nbsp;IT00366330462 –
      <a
        target="_blank"
        href="https://www.foxy.eu/pl/privacy-cookie-policy/?_gl=1*45xfdu*_up*MQ..*_ga*MTI3OTM3OTY4My4xNzE0NDgxNTc5*_ga_9V7VDVTMJK*MTcxNDQ4MTU3OC4xLjAuMTcxNDQ4MTU3OC4wLjAuMA.."
        >polityka&nbsp;prywatności</a
      >
      –
      <a
        target="_blank"
        href="https://www.foxy.eu/pl/privacy-cookie-policy/?_gl=1*45xfdu*_up*MQ..*_ga*MTI3OTM3OTY4My4xNzE0NDgxNTc5*_ga_9V7VDVTMJK*MTcxNDQ4MTU3OC4xLjAuMTcxNDQ4MTU3OC4wLjAuMA.."
        >polityka&nbsp;cookies</a
      >
    </p>
  </div>
</footer>
