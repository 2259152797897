import { Component } from '@angular/core';
import { AnimateModule } from '@app/components/animate/animate.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.scss'],
  imports: [AnimateModule, NgOptimizedImage],
  standalone: true,
})
export class TopSectionComponent {}
