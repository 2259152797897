import { Route } from '@angular/router';
import { MainPageComponent } from './pages/main-page/main-page.component';

// const ipGuard: CanActivateFn = ():
//   | Observable<boolean | UrlTree>
//   | Promise<boolean | UrlTree>
//   | boolean
//   | UrlTree => {
//   //     return this._ipService.checkIfIpAllowed().pipe(
//   //       catchError(() => {
//   //         this._store.dispatch(
//   //           new Navigate([`/${RouteSlug.SITE_UNDER_CONSTRUCTION}`]),
//   //         );
//   //         return of(false);
//   //       }),
//   //       map(() => true),
//   //     );
//   return true;
// };

export const appRoutes: Route[] = [
  { path: '', component: MainPageComponent },
  {
    path: 'zdrapka',
    loadComponent: () =>
      import('./pages/scratch-card/scratch-card.component').then(
        ({ ScratchCardComponent }) => ScratchCardComponent,
      ),
  },
  {
    path: 'bank/:token',
    loadComponent: () =>
      import('./pages/prize-claim-page/prize-claim-page.component').then(
        ({ PrizeClaimPageComponent }) => PrizeClaimPageComponent,
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found-page/not-found-page.component').then(
        ({ NotFoundPageComponent }) => NotFoundPageComponent,
      ),
  },
];
