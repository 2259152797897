import { Directive, ElementRef, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { register } from 'swiper/element/bundle';

register();

@Directive({
  selector: '[appSwiper]',
  standalone: true,
})
export class SwiperDirective {
  private readonly _swiperElement: HTMLElement;

  constructor(private readonly _elementRef: ElementRef<HTMLElement>) {
    this._swiperElement = this._elementRef.nativeElement;
  }

  private _config: SwiperOptions | null = null;

  get config(): SwiperOptions | null {
    return this._config;
  }

  @Input()
  set config(value: SwiperOptions | null) {
    this._config = value;

    if (!this._config) {
      return;
    }
    Object.assign(this._swiperElement, this._config);
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._swiperElement.initialize();
    });
  }
}
