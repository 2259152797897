import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnimateModule } from '@app/components/animate/animate.module';
import { RewardType } from '@app/models/reward-type';
import { WinnerListItem } from '@app/models/winner-list-item';
import { groupBy } from 'lodash';
import { compareDesc } from 'date-fns';

@Component({
  selector: 'app-winners-section',
  templateUrl: './winners-section.component.html',
  styleUrls: ['./winners-section.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, AnimateModule, NgClass],
})
export class WinnersSectionComponent {
  private readonly _deviceDetectorService = inject(DeviceDetectorService);
  public readonly isMobile = this._deviceDetectorService.isMobile();

  public winners = input.required<
    {
      week: string;
      weekData: {
        date: string;
        dayData: {
          type: RewardType;
          reward: string;
          firstName: string;
          threeLastPhoneNumbers: string;
        }[];
      }[];
    }[],
    WinnerListItem[]
  >({
    transform: (value: WinnerListItem[]) => {
      if (!value) {
        return [];
      }
      const weeks: { [week: string]: WinnerListItem[] } = groupBy(
        value,
        (winner) => winner.tydzien,
      );
      return Object.keys(weeks)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .map((week) => {
          const days = groupBy(weeks[week], (day) => day.dzien);
          return {
            week,
            weekData: Object.keys(days)
              .sort((a, b) => compareDesc(new Date(a), new Date(b)))
              .map((day) => {
                return {
                  date: day,
                  dayData: days[day].map((dayData) => ({
                    type:
                      dayData.nazwa.trim().toLowerCase() === 'przelew 5000 pln'
                        ? 'main'
                        : 'second',
                    reward: dayData.nazwa,
                    firstName: dayData.imie,
                    threeLastPhoneNumbers: dayData.tel,
                  })),
                };
              }),
          };
        });
    },
  });
}
