<section class="app-rew">
  <h2
    [appAnimate]="'fadeInRight'"
    [speed]="'fast'"
    [aos]="0.2"
    [once]="true"
    [delay]="'100'"
    class="app-rew--h2 app-title navy-blue text-center"
  >
    Pula nagród <br class="d-none d-sm-none" />w loterii Foxy
  </h2>

  <div class="container app-rew__con">
    <div
      class="app-rew__con__box"
      [appAnimate]="'bounceInUp'"
      [aos]="0.2"
      [once]="true"
      [delay]="'100'"
    >
      <img
        ngSrc="assets/images/rewards/61.webp"
        alt="61"
        height="308"
        width="307"
        priority="false"
        class="app-rew__con__box--qua"
      />
      <p class="app-rew__con__box--tit">Przelewy bankowe</p>
      <p class="app-rew__con__box--p">
        o wartości 5 000 zł na rodzinne przyjemności
      </p>
      <img
        ngSrc="assets/images/rewards/5000.webp"
        alt="5000"
        height="632"
        width="1238"
        priority="false"
        class="d-none d-md-block h-auto app-rew__con__box--rew"
      />
      <img
        ngSrc="assets/images/rewards/5000-mob.webp"
        alt="5000"
        height="714"
        width="780"
        priority="false"
        class="d-md-none app-rew__con__box--rew"
      />
    </div>
    <div
      class="app-rew__con__box"
      [appAnimate]="'bounceInUp'"
      [aos]="0.2"
      [once]="true"
      [delay]="'300'"
    >
      <img
        ngSrc="assets/images/rewards/366.webp"
        alt="366"
        height="308"
        width="307"
        priority="false"
        class="app-rew__con__box--qua"
      />
      <p class="app-rew__con__box--tit">e-vouchery</p>
      <p class="app-rew__con__box--p">o wartości od 100 do 200 zł</p>
      <img
        ngSrc="assets/images/rewards/vouchers.webp"
        alt="vouchers"
        height="632"
        width="1238"
        priority="false"
        class="d-none d-md-block h-auto app-rew__con__box--rew"
      />
      <img
        ngSrc="assets/images/rewards/vouchers-mob.webp"
        alt="vouchers"
        height="685"
        width="780"
        priority="false"
        class="d-md-none app-rew__con__box--rew"
      />
    </div>
  </div>
</section>
