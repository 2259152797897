<div class="app-hea" id="accountHeader">
  <nav [ngClass]="{ open: navbarExpanded }" class="app-hea__navbar">
    <div class="app-hea__navbar__wrap navbar navbar-expand-md">
      <button
        (click)="onNavbarToggle()"
        [ngClass]="{ collapsed: !navbarExpanded }"
        class="app-hea__navbar__wrap--toggle navbar-toggler ml-auto"
        title="toggle"
        type="button"
      >
        <span
          [ngClass]="{ expanded: navbarExpanded }"
          class="app-hea__navbar__wrap--toggle--icon"
        >
          <span class="top"></span>
          <span class="center"></span>
          <span class="bottom"></span>
        </span>
      </button>

      <div
        [ngClass]="{ show: navbarExpanded }"
        class="navbar-collapse collapse justify-content-end"
      >
        <ul class="navbar-nav text-md-center">
          <li>
            <a (click)="onClick(SectionType.RULES)"> Zasady </a>
          </li>
          <li>
            <a (click)="onClick(SectionType.PRODUCTS)"> Produkty </a>
          </li>
          <li>
            <a (click)="onClick(SectionType.WINNERS_LIST)">
              Lista zwycięzców
            </a>
          </li>
          <li>
            <a (click)="onClick(SectionType.FAQ)"> FAQ </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
