import { NgOptimizedImage } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, AccordionModule],
  encapsulation: ViewEncapsulation.None,
})
export class FaqSectionComponent {
  public readonly questions = [
    {
      question: 'W jakim terminie należy zrobić zakupy?',
      answer:
        'Zakup Produktów upoważniający do wzięcia udziału w Loterii obejmuje okres od dnia 1 maja 2024 r. do dnia 30 czerwca 2024 r.',
    },
    {
      question: 'W jakim terminie należy dokonać zgłoszenia?',
      answer:
        'Dokonywanie zgłoszeń w Loterii obejmuje okres od dnia 1 maja 2024 r. do dnia 30 czerwca 2024 r.',
    },
    {
      question: 'Jak mogę wziąć udział w loterii?',
      answer: `
        W celu wzięcia udziału w Loterii należy zakupić w okresie od dnia 1 maja 2024 r. do dnia 30 czerwca 2024 r. 
        przynajmniej jeden produkt Foxy, a następnie w okresie od dnia 1 maja 2024 r. (od godz. 00:00:01) do dnia 30 czerwca 2024 r. 
        (do godziny 23:59:59) dokonać zgłoszenia w Loterii za pośrednictwem Strony Loterii wypełniając formularz i załączając zdjęcie paragonu. 
      `,
    },
    {
      question: `
        Czy mogę wziąć udział w loterii inaczej niż poprzez formularz na stronie internetowej? <br/>
        Czy będzie można zgłosić udział np. w sklepie?
      `,
      answer: `
        Nie. Udział w loterii można wziąć wyłącznie przez stronę Loterii: 
        <a href="https://loteriafoxy.pl">https://loteriafoxy.pl</a> poprzez wypełnienie formularza.
      `,
    },
    {
      question: 'W jaki sposób dowiem się o wygranej?',
      answer: `Po dokonaniu Zgłoszenia Uczestnikowi udostępnione zostanie zakryte pole eZdrapki. 
        Uczestnik jest obowiązany odsłonić pole eZdrapki. 
        W przypadku wylosowania Nagrody po odsłonięciu eZdrapki pojawi się symbol Nagrody oraz informacja o wygranej Nagrodzie. 
        W przypadku niewygrania Nagrody po odsłonięciu pola eZdrapki nie pojawi się symbol 
        Nagrody oraz wyświetlona zostanie informacja o braku wygranej.`,
    },
    {
      question: 'Czy można wziąć udział w loterii kilkukrotnie?',
      answer: `
        Każdy Uczestnik może dokonać dowolnej liczby Zgłoszeń, 
        z tym zastrzeżeniem, że liczba Zgłoszeń musi odpowiadać 
        liczbie posiadanych przez Uczestnika dowodów zakupu Produktów Foxy. 
      `,
    },
    {
      question: 'Czy mogę zgłosić kilkukrotnie ten sam paragon?',
      answer: `
        W ramach jednego Zgłoszenia Uczestnik może zarejestrować wyłącznie jeden paragon albo fakturę imienną, 
        niezależnie od ilości zakupionych produktów Foxy. 
        Uczestnik może zgłosić jeden dowód zakupu Produktu wyłącznie jeden raz. 
        W przypadku, gdy w Loterii jeden dowód zakupu zostanie zarejestrowany więcej niż jeden raz, 
        pod uwagę brane będzie pierwszeństwo rejestracji dowodu zakupu.
       `,
    },
    {
      question: 'Kiedy mogę się spodziewać nagrody?',
      answer: `
        W terminie 5 dni roboczych od daty odsłonięcia eZdrapki informującej o wygranej nagrodzie w postaci vouchera cyfrowego 
        Organizator wyda zwycięskiemu Uczestnikowi voucher cyfrowy, 
        chyba że zachodzić będzie konieczność weryfikacji dowodu zakupu. 
        Wydanie nagrody w postaci vouchera cyfrowego nastąpi za pośrednictwem wiadomości e-mail oraz wiadomości SMS.
        Wydanie nagrody pieniężnej nastąpi w drodze przelewu środków pieniężnych w terminie 5 dni 
        roboczych od daty podania przez Uczestnika numeru rachunku bankowego, podanego w formularzu po wygranej.
      `,
    },
    {
      question: 'Czy zostanę poinformowany o tym, że nie wygrałem nagrody?',
      answer: `
        Tak, w przypadku niewygrania Nagrody po odsłonięciu pola eZdrapki nie pojawi się 
        symbol Nagrody oraz wyświetlona zostanie informacja o braku wygranej.
      `,
    },
    {
      question: 'Jakie są nagrody?',
      answer: `
        W Loterii przyznawane są następujące nagrody: <br>
        <ol class="mt-1">
          <li class="mb-1">61 nagród pieniężnych w wysokości jednostkowej 5.000 zł</li>
          <li class="mb-1">
              61 nagród w postaci cyfrowych voucherów Smyk o wartości jednostkowej 200 zł 
              z przeznaczeniem do wykorzystania na zakup towarów lub usług w sklepach stacjonarnych 
              Smyk albo w sklepie internetowym <a target="_blank" href="https://www.smyk.com/">www.smyk.com</a>, 
          </li>
          <li class="mb-1">
              61 nagród w postaci cyfrowych voucherów Decathlon o wartości jednostkowej 200 zł 
              z przeznaczeniem do wykorzystania na zakup towarów lub usług w sklepach stacjonarnych 
              Decathlon albo w sklepie internetowym <a target="_blank" href="https://www.decathlon.pl/">www.decathlon.pl</a>, 
          </li>
          <li class="mb-1">
              122 nagrody w postaci cyfrowych voucherów Empik o wartości jednostkowej 100 zł 
              z przeznaczeniem do wykorzystania na zakup towarów lub usług w sklepie internetowym 
              <a target="_blank" href="https://www.empik.com/">www.empik.com</a>, 
          </li>
          <li class="mb-1">
              61 nagród w postaci cyfrowych voucherów Smyk o wartości jednostkowej 100 zł 
              z przeznaczeniem do wykorzystania na zakup towarów lub usług w sklepach stacjonarnych 
              Smyk albo w sklepie internetowym <a target="_blank" href="https://www.smyk.com/">www.smyk.com</a>, 
          </li>
          <li>
              61 nagród w postaci cyfrowych voucherów Decathlon o wartości jednostkowej 100 zł, 
              z przeznaczeniem do wykorzystania na zakup towarów lub usług w sklepach stacjonarnych 
              Decathlon albo w sklepie internetowym <a target="_blank" href="https://www.decathlon.pl/">www.decathlon.pl</a>
          </li>
        </ol>
      `,
    },
    {
      question:
        'W jaki sposób/do czego wykorzystane zostaną dane, które podaję rejestrując się do loterii?',
      answer: `
        Organizator przetwarza dane osobowe w celu: <br>
        <ol class="mt-1">
          <li class="mb-1">
            przeprowadzenia Loterii, a w szczególności umożliwienia zgłoszenia Uczestnikowi udziału w Loterii, 
            wyłonienia zwycięzców Loterii oraz wydania nagród w Loterii – podstawą przetwarzania jest zgoda 
            Uczestnika (art. 6 ust. 1 lit. a RODO) oraz realizacja prawnie uzasadnionego interesu Organizatora 
            (art. 6 ust. 1 lit. f RODO), 
          </li>
          <li class="mb-1">
            rozpatrzenia reklamacji oraz obrony przed roszczeniami, które mogą być podnoszone przez 
            Uczestników – podstawą prawną jest realizacja prawnie uzasadnionego interesu Organizatora (art. 6 ust. 1 lit. f RODO), 
          </li>
          <li class="mb-1">
            wydania imiennego zaświadczenia o uzyskanej wygranej, prowadzenia ewidencji wygranych nagród oraz 
            ewidencji wydanych zaświadczeń – podstawą prawną jest realizacja obowiązków wynikających z 
            przepisów ustawy z dnia 19 listopada 2009 r. o grach hazardowych (art. 6 ust. 1 lit. c RODO), 
          </li>
          <li class="mb-1">
            wypełnienia obowiązków podatkowych wynikających w szczególności z przepisów ustawy o podatku 
            dochodowym od osób fizycznych, ustawy o rachunkowości oraz rozporządzenia Ministra Rozwoju i 
            Finansów z dnia 24 lutego 2017 r. w sprawie kontroli celno-skarbowej wykonywanej w zakresie 
            urządzania i prowadzenia gier hazardowych – podstawą prawną jest realizacja obowiązków 
            wynikających z przepisów prawa (art. 6 ust. 1 lit. c RODO).
          </li>
        </ol>
      `,
    },
    {
      question:
        'Czy zakupy dokonane na fakturę na firmę mogę zarejestrować w loterii?',
      answer: `Nie. Zgłoszone do loterii mogą zostać tylko paragony lub faktury imienne.`,
    },
    {
      question: 'Jakie produkty biorą udział w loterii?',
      answer: `
        <ul>
          <li class="mb-1">Papier Toaletowy Foxy Love the Bee 4 rolki</li>
          <li class="mb-1">Papier Toaletowy Foxy Jedwab 8 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Jedwab 16 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Artistico 4 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Artistico 8 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Artistico 16 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Artistico 24 rolki</li>
          <li class="mb-1">Papier Toaletowy Foxy Camilla 8 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy Camilla 16 rolek</li>
          <li class="mb-1">Papier Toaletowy Foxy MEGA 4 rolki</li>
          <li class="mb-1">Papier Toaletowy Foxy Cotton 4 rolki</li>
          <li class="mb-1">Papier Toaletowy Foxy Quatrefeuilles 10 rolek</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Mega Jumbo 1 rolka</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Love the Bee 2 rolki</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Mega 2 rolki</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Mega 3 rolki</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Tornado 1 rolka</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Asso 2 rolki</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Cartapaglia 2 rolki</li>
          <li class="mb-1">Ręczniki kuchenne Foxy Asso Ultra 2 rolki</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Cream 10 paczek</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Hipoalergiczne 10 paczek</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Cotton 10 paczek</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Mega 10 paczek</li>
          <li class="mb-1">Chusteczki kosmetyczne Foxy Mega 200 listków</li>
          <li class="mb-1">Chusteczki kosmetyczne Foxy Cotton 90 listków</li>
          <li class="mb-1">Chusteczki kosmetyczne Foxy Cotton 60 listków</li>
          <li>Chusteczki kosmetyczne Foxy Cream 75 listków</li>
        </ul>
      `,
    },
    {
      question: 'W jaki sposób mogę zgłosić reklamację?',
      answer: `
        Wszelkie reklamacje dotyczące urządzenia i przebiegu Loterii mogą być składane począwszy 
        od dnia 1 maja 2024 r. do dnia 25 października 2024 r. Reklamacje powinny być zgłaszane 
        na piśmie listem poleconym wysłanym na adres Organizatora, tj. 
        HUMAN2HUMAN sp. z o.o., ul. Bastionowa 37, 61- 663 Poznań z dopiskiem „Loteria Foxy – reklamacja” 
        albo za pośrednictwem wiadomości poczty elektronicznej przesłanej na adres: <a href="mailto:loteriafoxy@h2h.tech">loteriafoxy@h2h.tech</a>
      `,
    },
    {
      question:
        'Czy zostanie uznany zakup pojedynczej paczki chusteczek higienicznych?',
      answer: `
        Nie, paragony zawierające pojedyncze opakowania chusteczek higienicznych nie będę weryfikowane pozytywnie. Pełna lista produktów objętych loterią dostępna jest w Regulaminie oraz na stronie loterii. Uznawane są wielopaki chusteczek higienicznych:
        <ul>
          <li class="mb-1">Chusteczki higieniczne Foxy Cream 10 paczek</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Hipoalergiczne 10 paczek</li>
          <li class="mb-1">Chusteczki higieniczne Foxy Cotton 10 paczek</li>
          <li>Chusteczki higieniczne Foxy Mega 10 paczek</li>
        </ul>
      `,
    },
  ];
}
