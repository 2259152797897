import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  signal,
} from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class ScrollToTopComponent {
  public windowScrolled = signal<boolean>(false);

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    if (
      window.scrollY ||
      this._document.documentElement.scrollTop ||
      this._document.body.scrollTop > 100
    ) {
      this.windowScrolled.set(true);
    } else if (
      (this.windowScrolled && window.scrollY) ||
      this._document.documentElement.scrollTop ||
      this._document.body.scrollTop < 10
    ) {
      this.windowScrolled.set(false);
    }
  }

  public scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
