<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled() }">
  <button (click)="scrollToTop()">
    <img
      priority="false"
      ngSrc="assets/images/arrow.svg"
      alt="arrow"
      height="46"
      width="46"
    />
  </button>
</div>
