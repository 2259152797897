import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';

declare const gtag: (...args: any[]) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public readonly ID_GTM_SCRIPT = 'app-gtm-script';
  public readonly ID_GTM_SCRIPT_2 = 'app-gtm-script-2';
  public readonly ID_GTM_IFRAME = 'app-gtm-iframe';

  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this._rendererFactory.createRenderer(null, null);
  }

  public getDataLayer(): any {
    this._createDataLayer();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window['dataLayer'];
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!environment.gtm) {
        reject('Lack of gtm key.');
        return null;
      }
      if (this._scriptExists()) {
        return resolve;
      }

      this._createDataLayer();
      this._pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const gtmScript = this._renderer2.createElement('script');
      gtmScript.id = this.ID_GTM_SCRIPT;
      gtmScript.async = true;
      gtmScript.onload = resolve;
      gtmScript.onerror = reject;
      gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${environment.gtm}`;
      this._document.head.insertBefore(
        gtmScript,
        this._document.head.firstChild,
      );

      const gtmScript2 = this._renderer2.createElement('script');
      gtmScript2.id = this.ID_GTM_SCRIPT_2;
      gtmScript2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.gtm}');
      `;
      this._document.head.insertBefore(
        gtmScript2,
        this._document.head.firstChild,
      );

      const ifrm = this._renderer2.createElement('iframe');
      ifrm.setAttribute(
        'src',
        `//www.googletagmanager.com/ns.html?id=${environment.gtm}`,
      );
      ifrm.style.width = '0';
      ifrm.style.height = '0';
      ifrm.style.display = 'none';
      ifrm.style.visibility = 'hidden';

      const noscript = this._renderer2.createElement('noscript');
      noscript.id = this.ID_GTM_IFRAME;
      noscript.appendChild(ifrm);

      this._document.body.insertBefore(
        noscript,
        this._document.body.firstChild,
      );

      return null;
    });
  }

  public gtag(...args: any[]): void {
    if (!environment.production) {
      console.warn('gtag', args);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag(...args);
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_GTM_SCRIPT);
    return !!script;
  }

  private _createDataLayer(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window['dataLayer'] = window['dataLayer'] || [];
  }

  private _pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
}
