import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-follow-section',
  templateUrl: './follow-section.component.html',
  styleUrls: ['./follow-section.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage],
})
export class FollowSectionComponent {}
