<section class="app-pro">
  <div class="container app-pro__con">
    <h2
      class="app-title app-pro__con--h2"
      [appAnimate]="'landing'"
      [speed]="'fast'"
      [aos]="0.2"
      [once]="true"
      [delay]="'100'"
    >
      Produkty biorące udział w akcji
    </h2>

    @if (isMobile) {
      <ul class="app-pro__con__pro-mob list-unstyled">
        @for (p of products; track $index) {
          <li>
            <h3>{{ p.category }}</h3>
            <swiper-container
              #swiper
              [config]="swiperConfig"
              class="dvp-hs__con__wr__sw"
              appSwiper
              init="true"
            >
              @for (l of p.list; track i; let i = $index) {
                <swiper-slide swiperSlide>
                  <a href="{{ l.url }}" target="_blank">
                    <img
                      [ngSrc]="'assets/images/products/' + l.image"
                      [fill]="true"
                      [alt]="l.name"
                    />
                    <div>
                      <h3>{{ l.name }}</h3>
                      <p>{{ p.category }}</p>
                    </div>
                  </a>
                </swiper-slide>
              }
            </swiper-container>
          </li>
        }
      </ul>
    } @else {
      <ul
        class="app-pro__con__pro"
        [appAnimate]="'bounceInUp'"
        [speed]="'normal'"
        [aos]="0.3"
        [once]="true"
        [delay]="'100'"
      >
        @for (p of products; track $index) {
          <li class="app-pro__con__pro__li category">
            <a role="link" href="{{ p.link }}" target="_blank">
              <h3>{{ p.category }}</h3>
              <p>
                <span>{{ p.moreTxt }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                >
                  <g transform="translate(3944 -4817)">
                    <g
                      transform="translate(-3944 4817)"
                      fill="none"
                      stroke-width="1"
                    >
                      <circle cx="13" cy="13" r="13" stroke="none" />
                      <circle cx="13" cy="13" r="12.5" fill="none" />
                    </g>
                    <g transform="translate(-9.5 -98.106)">
                      <line
                        x2="8"
                        transform="translate(-3925.5 4927.803)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        x2="5.228"
                        transform="translate(-3920.348 4924.106) rotate(45)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                      <line
                        x2="5.228"
                        transform="translate(-3920.348 4931.5) rotate(-45)"
                        fill="none"
                        stroke-linecap="round"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                </svg>
              </p>
            </a>
          </li>
          @for (l of p.list; track $index) {
            <li class="app-pro__con__pro__li product">
              <a href="{{ l.url }}" target="_blank">
                <img
                  [ngSrc]="'assets/images/products/' + l.image"
                  [fill]="true"
                  [alt]="l.name"
                />
                <div>
                  <h3>{{ l.name }}</h3>
                  <p>{{ p.category }}</p>
                </div>
              </a>
            </li>
          }
        }
      </ul>

      <div
        class="row"
        [appAnimate]="'slideInUp'"
        [speed]="'normal'"
        [aos]="0.2"
        [once]="true"
      >
        <div class="col-12 text-center">
          <a
            class="app-btn"
            href="https://www.foxy.eu/pl/nasze-produkty"
            target="_blank"
          >
            WIĘCEJ O PRODUKTACH FOXY
          </a>
        </div>
      </div>
    }
  </div>
</section>
