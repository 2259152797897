import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { AnimateModule } from '../../../../components/animate/animate.module';

@Component({
  selector: 'app-what-todo-section',
  templateUrl: './what-todo-section.component.html',
  styleUrls: ['./what-todo-section.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, AnimateModule],
})
export class WhatTodoSectionComponent {}
