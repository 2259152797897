<section class="app-fol">
  <div class="container app-fol__con">
    <div class="app-fol__con__lef">
      <h2 class="app-fol__con__lef--h2 app-title ps-md-0 pe-md-0">
        Śledź <br class="d-block d-md-none" />nasze kanały <br />
        <span>społecznościowe</span>
      </h2>

      <ul class="list-unstyled">
        <li>
          <img
            ngSrc="assets/images/follow/youtube-pictures.webp"
            alt="youtube pictures"
            width="781"
            height="500"
            priority="false"
            class="d-block d-md-none"
          />
          <p>
            <img
              ngSrc="assets/images/youtube.svg"
              alt="youtube"
              width="40"
              height="40"
              priority="false"
            />
            <span> YouTube </span>
          </p>
          <p>
            Oglądaj reklamy. Poznaj wszystkie samouczki <br />
            wideo Foxy i twórz papierowe cudeńka z dziećmi.
          </p>
          <a
            class="app-btn"
            target="_blank"
            href="https://www.youtube.com/user/FoxyPolska"
          >
            PRZECHODZĘ
          </a>
        </li>
        <li>
          <img
            ngSrc="assets/images/follow/facebook-pictures.webp"
            alt="facebook pictures"
            width="780"
            height="536"
            priority="false"
            class="d-block d-md-none"
          />
          <p>
            <img
              ngSrc="assets/images/facebook.svg"
              alt="facebook"
              width="40"
              height="40"
              priority="false"
            />
            <span> Facebook </span>
          </p>
          <p>
            Chcesz pomóc nam ulepszyć stronę Foxy? <br />
            Powiedz nam, co chciałbyś/abyś tam znaleźć.
          </p>
          <a
            class="app-btn"
            target="_blank"
            href="https://www.facebook.com/FoxyPolska"
          >
            PRZECHODZĘ
          </a>
        </li>
        <li>
          <img
            ngSrc="assets/images/follow/instagram-pictures.webp"
            alt="instagram pictures"
            width="782"
            height="500"
            priority="false"
            class="d-block d-md-none"
          />
          <p>
            <img
              ngSrc="assets/images/instagram.svg"
              alt="instagram"
              width="40"
              height="40"
              priority="false"
            />
            <span> Instagram </span>
          </p>
          <p>Poznaj papierowy świat Foxy!</p>
          <a
            class="app-btn"
            target="_blank"
            href="https://www.instagram.com/foxy.polska/"
          >
            PRZECHODZĘ
          </a>
        </li>
      </ul>
    </div>

    <img
      ngSrc="assets/images/follow/follow-pictures.webp"
      alt="pictures"
      width="1402"
      height="1883"
      priority="false"
      class="app-fol__con--img d-none d-md-block"
    />
  </div>
</section>
